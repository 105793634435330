#services {
    padding: 3em;

    #broadcasting-container,
    #production-container{
        background-color: #edeae6;
        padding: 5em 4em;
    }
    .service-container {
        padding: 5em 4em;
        .grey-block-col{
            background-color: #666666;
        }
        .row:first-of-type {
        }

        .service-block {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display:flex;
            flex-direction: column;
            p{
                padding: 1em 0px;
            }

            .img-inner{
                margin-top: auto;
            }
            h1{
                font-size: 3em;
            }

            .service-block-title{
                height: 100%;
            }

            .br-gradient-span {
                display: block;
                height: .2rem;
                width: 30%;
                margin: .5rem auto 1rem;
                border-radius: 5px;
                background: $red;
            }


            div {
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, .5);
                font-size: 1.4em;
                color: #fff;
                list-style: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 250px;
            }
        }

        .service-block:nth-child(odd) {
            background-color: $gray;
        }


        .service-block-list {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-direction: column;
            padding: 1em;

            .service-block-second-title{
                color: $white;
                text-align: center;
                font-size: 2em;
                font-weight: 600;
            }

            ul {
                text-align: center;

                li {
                    font-family: $roboto;
                    color: $white;
                    font-size: 1.1em;
                }
            }
        }
        #service-block-production {
            //background-image: url('./../images/services/services-production.png');
            background-image: url('./../images/services/IMG_2954.JPG');
            min-height: 100%;
        }

        #service-block-post {
            //background-image: url('./../images/services/services-post.png');
            background-image: url('./../images/services/IMG_1095.jpg');
            min-height: 100%;

        }

        #service-block-broadcasting {
            background-image: url('./../images/services/services-broadcasting.png');
            min-height: 100%;
        }

        #service-block-studio {
            background-image: url('./../images/services/services-studio.png');
            min-height: 100%;
        }
    }
}
///////////////////////////////////
// on click show and hide functions
///////////////////////////////////
.show{
    display: block !important;
}



.sticky {
    position: fixed;  
    top: 0; 
    z-index: 100;
  }

  .grow{
    height: auto !important;   
    transition: all 1s ease-in-out;
    animation-duration: 1s;
    animation-name: growOut;
  }
  @keyframes growOut {
    from {      
      height: 0;
    }
  
    to {
        height: auto !important;
    }
}

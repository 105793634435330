@import'jquery';
@import'variables';
@import'auxclasses.scss';
@import'partials/index.scss';
@import'studios/index.scss';
@import'about/index.scss';
@import'work/index.scss';
@import'home/index.scss';
@import'services/index.scss';


html {
    scroll-behavior: smooth;
  }

html,
body{
    overflow-x: hidden;
}



*{
  font-family: $lato;
}



// CLEAR WIDGET STYLES
.eapps-remove-link,
.eui-widget-title {
  display: none !important;
}

.display-none{
  display: none !important;
}
#eapps-instagram-feed-1 > a{
  position: fixed !important;
}

#eapps-instagram-feed-1 > a[style]{
  display: none !important;
}


/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu{ display: none; }
  .navbar .nav-item:hover .nav-link{   }
  .navbar .nav-item:hover .dropdown-menu{ display: block; }
  .navbar .nav-item .dropdown-menu{ margin-top:0; }
}
/* ============ desktop view .end// ============ */

.studio-jumbo{
  background-image: url('./../images/heros/hero-img-2.jpg');
}

.work-jumbo{
  background-image: url("./../images/studios/circles-studio-e.jpg");
}

.shadow{
  min-height: 169px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
}
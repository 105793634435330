#services-bg-img{
    background-image: url('./../images/services/services-production.png') !important;
}
.services-page-header {
    background-image: url('./../images/jumboSlide/slide-3.jpg');
    //background-image: url('./../images/services/services-production.png');
    background-size: cover;
    background-position: center;
    // height: 350px;
    height: 450px;

    .margin-header {
        margin-left: 3em;
        margin-top: 100px;
    }

    .services-page-header-content {
        color: $white;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .shadow {
            background-color: rgba(0, 0, 0, .7);
            padding: 1em 3em 1.8em;
        }

        ul {
            li {
                font-size: 1.2em;
            }
        }

        .show-mobile-only {
            display: none;
        }
    }
}


@media(max-width:768px) {
    .services-page-header {
        margin-left: auto;
        margin-right: auto;

        .margin-header {
            margin-left: 0;
            width: 100%;
        }

        .services-page-header-content {
            .hide-mobile {
                display: none;
            }

            .show-mobile-only {
                display: block;
            }
        }
    }
}
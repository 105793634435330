#profiles{
    background-size: contain;
    background-position: center;
    .about-titles{
        color: $red;
        //font-weight: 500;
    }

    .profiles-row{
        justify-content: space-around;
        padding: 0 3em;
    }

    .card{
        width: 32.5%;
        padding: 0;

        .img-container{
            background-size: cover;
            background-position: center;
            height: 300px;
        }
        
        // PROFILE PIC HOVER SETTINGS
        #img-container-1{
            background-image: url("./../images/team/TeamSlide-4.jpg");
        }
        #img-container-1:hover{
            background-image: url("./../images/team/TeamSlide-7.jpg");
        }
        
        #img-container-2{
            background-image: url("./../images/team/TeamSlide-2.jpg");
        }
        #img-container-2:hover{
            background-image: url("./../images/team/TeamSlide-3.jpg");
        }
        
        #img-container-3{
            background-image: url("./../images/team/TeamSlide-5.jpg");
        }
        #img-container-3:hover{
            background-image: url("./../images/team/TeamSlide-8.jpg");
        }


        #img-container-4{
            background-image: url("./../images/team/TeamSlide-4-new.jpg");
        }
        #img-container-4:hover{
            background-image: url("./../images/team/TeamSlide-4-hover.jpg");
        }
        #img-container-5{
            background-image: url("./../images/team/ESS_V4-40.jpg");
        }
        #img-container-5:hover{
            background-image: url("./../images/team/ESS_V4-42.jpg");
        }
        #img-container-6{
            background-image: url("./../images/team/ESS_V4-36.jpg");
        }
        #img-container-6:hover{
            background-image: url("./../images/team/ESS_V4-37.jpg");
        }
        
        .card-body{
            //background-color: $gray;
            background-color: #f0f0f0;
            h5{
                color: $red;
                font-weight: 700;
            }
            p{}
            a{
                background-color: #3b3838;
                border-color: #3b3838;
                color: #ffffff;
            }
            a:hover{
                background-color: $black;
            }
        }
    }
}

@media(max-width:768px) {
    #profiles{      
        #team{
            width: 100%;
        .about-titles{}    
        .profiles-row{
            // display: block;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1em auto;
        }    
        .card{
            width: 70%;
            // width: 300px;
            margin: 1em 0px;
            .img-container{}                                           
            .card-body{
                h5{}
                p{}
                a{}
                a:hover{}
            }
        }
    }
}
}

#quadrants {
    position: relative;
    padding: 2em;
    min-height: 300px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,234,230,1) 100%);
    .quadrant-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 200px 200px 200px;
        gap: 3em;
        padding: 3em 0px;
        z-index: 100;

        .quad {
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
            overflow: hidden;
        }

        .quad-content {
            background: rgba(0, 0, 0, .5);
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                color: $white;
                font-size: 2em;
            }
        }




        // QUAD POSITIONING
        #quad-one {
            background-color: red;
            grid-column: 1/4;
            grid-row: 1/2;
            background-image: url('./../images/quads/production.jpg');
        }

        #quad-two {
            background-color: green;
            grid-column: 2/4;
            grid-row: 2/3;
            background-image: url('./../images/quads/post.jpg');
        }

        #quad-three {
            background-color: yellow;
            grid-column: 4/5;
            grid-row: 1/3;
            background-image: url('./../images/quads/broadcasting.jpg');
            background-position: 75% 50%;
        }

        #quad-four {
            background-color: blue;
            grid-column: 1/2;
            grid-row: 2/4;
            background-image: url('./../images/quads/creative.jpg');
            background-position: 15% 50%;
        }

        #quad-five {
            background-color: purple;
            grid-column: 2/5;
            grid-row: 3/4;
            background-image: url('./../images/quads/studio.png');
        }
    }
}
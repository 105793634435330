#jumbo {
    .jumbo-header {
        background-image: url('./../images/jumboSlide/slide-3.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 350px;
        height: 450px;

        .shadow {
            background-color: rgba(59, 56, 56, .8);
            text-align: left;
            padding: 1em 3em 1.8em;
        }


        .jumbo-page-header-content {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            ul {
                li {
                    font-size: 1.2em;
                    color: $white;
                }
            }
        }
    }
}

.studio-page {
  .studio-page-header {
    background-image: url('./../images/jumboSlide/slide-3.jpg');
    background-size: cover;
    background-position: center;
    // height: 350px;
    height: 450px;

    .studio-page-header-content {
      // background-color: rgba(0, 0, 0, .7);
      color: $white;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .shadow {
        // background-color: rgba(59, 56, 56, 1);
        background-color: rgba(59, 56, 56, 0.8);
        // background-color: red;
        // width: 40%;
        padding: 1em 3em 1.8em;
        margin-left: 3em;
        margin-top: 100px;
      }

      ul {
        li {
          font-size: 1.2em;
        }
      }
    }
  }

  .studio-blurb {
    background: radial-gradient(ellipse at center, #FFFFFF, #c0bfbe);

    p {
      color: #181c16;
      font-size: 1.4em;
      //font-weight: 300;
      font-weight: 300;
      text-align: center;
      //margin-bottom: 2.5rem;
      margin-bottom: 0;
    }
  }

  .studio-details {
    padding: 3em;

    ul {
      list-style: none;

      li {
        padding: 0.5rem 1rem;
        text-decoration: none;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
      }
    }
  }

  .four-images {
    margin-top: 2em;
    text-align: center;

    h1 {
      margin-bottom: 1em;
      font-weight: 300;
      padding-bottom: .5em;
    }

    a {
      img {
        width: 24%;
        transition: all ease-in-out .2s;
      }
    }

    a:hover {
      img {
        margin-top: -15px;
      }
    }

    a:hover,
    a:active,
    a:focus,
    a:focus-within {
      color: transparent !important;
    }
  }

  // GALLERY
  .gallery-row {
    background: #edeae6;
    background: linear-gradient(180deg, #edeae6 0%, #ffffff 100%);

    .set-photos-row {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .set-photos-link {
        width: 14rem;
        margin: 2rem;
      }
    }
  }

  // PAGE CTA
  .studio-cta {
    //clip-path: polygon(100% 0, 100% 75%, 0 100%, 0 25%);
    background: $cta-gradient;
    min-height: 200px;
    padding: 1rem 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .display-4 {
      color: $red;
      //font-weight: 500;
    }

    .btn {
      background-color: #3b3838;
      color: $white;
    }

    .btn:hover {
      background-color: $white;
      color: #3b3838;
      border: solid 1px #3b3838;
    }
  }

  // studio-extras
  .studio-extras {
    background-color: #ffffff;
    background: linear-gradient(180deg, white 0%, #edeae6 100%);
    padding: 3em;

    .studioEntryDetails {
      ul {
        padding: 0;

        li {
          padding: 0.5rem 1rem;
          list-style: none !important;
          text-decoration: none;
          border-bottom: 1px solid rgba(0, 0, 0, .125);
        }
      }
    }
  }

  // Set Gallery
  .set-gallery-row {
    // background: linear-gradient(180deg, #ffffff 0%, #edeae6 100%, );

    background: #edeae6;
    background: linear-gradient(180deg, #edeae6 0%, #ffffff 100%);
    padding: 0 3em;

    .display-4 {
      color: $red;
      font-weight: 500;
    }
  }

  #gallery-imgs-row {
    padding: 0 2.2em;

    #gallery-imgs {
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }
  }

  .set-gallery-img-link {
    display: block;
    //width: 24%;
    margin: 5px;
    overflow: hidden;
    transition: ease-in-out all .2s;
    max-height: 145px;
  }

  .set-gallery-img-link:hover {
    transform: scale(1.05);
  }

  // Studio Circles
  .studio-page__cirles-row {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 3em 5em;
    align-items: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(237, 234, 230, 1) 50%, rgba(255, 255, 255, 1) 100%);

    .display-4 {
      color: #cf0015;
      font-size: 3.5em;
      text-align: center;
      //font-weight: 500 !important;
    }

    .studio__circle {
      height: 200px;
      //width: 19.25%;
      width: 18%;
      // width: 15rem;
      margin: 5px;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease-in-out .5s;
      position: relative;

      .studio_circle-bg {
        background-position: center;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      a {
        color: #ffffff;
        font-size: 1.5em;
        text-decoration: none;
        overflow: hidden;
        position: absolute;
        z-index: 50;
        height: 100%;
        text-align: center;
        width: 100%;
        bottom: 0;

        p {
          background-color: rgba(59, 56, 56, .4);
          margin-bottom: 0;
          color: $white;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          padding-top: 7vh;
        }
      }

    }

    .studio__circle:hover {
      margin-top: -1em;
    }

    #studio__cicle-a {
      background-image: url('./../images/studios/circles-studio-a.jpg');
    }

    #studio__cicle-b {
      background-image: url('./../images/studios/circles-studio-b.jpg');
    }

    #studio__cicle-rooftoop {
      background-image: url('./../images/studios/circles-studio-rooftop.jpeg');
    }

    #studio__cicle-d {
      background-image: url('./../images/studios/cirlcles-studio-d.png');
    }

    #studio__cicle-e {
      background-image: url('./../images/studios/circles-studio-e.jpg');
    }

  }

}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  // Studio Circles
  .studio-page__cirles-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .studio__circle {
      height: 200px;
      width: 90% !important;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media(min-width: 575.99px) and (max-width: 767.98px) {
  // Studio Circles
  .studio-page__cirles-row {
    display: flex;
    justify-content: center;
    .studio__circle {
      height: 200px;
      width: 45% !important;

      .studio_circle-bg {
        background-position: center;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
  }
}


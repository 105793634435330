.topContent {
  //background: radial-gradient(ellipse at center, #FFFFFF, #c0bfbe);
background-color: #ffffff;
  .five-studios{
    color: #cf0015;
    font-size: 3.5em;
    text-align: center;
    font-weight: 300;
    margin-bottom: 0;
  }

  p {
    color: #181c16;
    font-size: 1.4em;
    font-weight: 300;
    //font-weight: 500;
    text-align: center;
    margin-bottom: 2.5rem;
  }
}

@import '../variables.scss';

#nav {
  background-color: rgba(59, 56, 56, 0.98);
  padding: 0 3em;
  //position: fixed;
  z-index: 1000;

  .btn-group {
    .btn {
      color: rgba(255, 255, 255, .55);
    }
    .dropdown-toggle{
      padding-left: 0;
      padding-right: 0;
    }
  }

  .btn-contact {
    background-color: $red;
    color: #ffffff;
    margin-left: 10px;
  }

  .btn-contact:hover {
    background-color: $red-hover;
  }
}



#work {
    .work-header {
        background-image: url('./../images/studios/circles-studio-e.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 350px;
        height: 450px;

        .shadow {
            background-color: rgba(59, 56, 56, .8);
            // background-color: red;
            text-align: left;
            // width: 45%;
            padding: 1em 3em 1.8em;
            margin-left: 3em;
            margin-top: 100px;
        }


        .work-page-header-content {
            // background-color: rgba(0, 0, 0, .7);
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            ul {
                li {
                    font-size: 1.2em;
                    color: $white;
                }
            }
        }
    }
}

@media(max-width:768px) {
    #work {
        .work-header {
            .shadow {
                margin-left: auto;
                margin-right: auto;
                width: 80% !important;
                padding: 1em 2em;
            }


            .work-page-header-content {}
        }
    }

    .hide-mobile {
        display: none;
    }

    .show-mobile-only {
        display: block !important;
    }
}